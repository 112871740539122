<template>
    <div :class="{ 'c-progress-bar': !isVertical, 'c-progress-bar c-progress-bar--is-vertical': isVertical }">
        <div class="c-progress-bar__labels">
            <div class="c-progress-bar__label || js-label" :data-start="label.percentage" :data-percentage="label.percentage" :data-index="index" :data-id="label.id" v-for="(label, index) in labels" :key="'label-' + label.id" v-bind:style="[ isVertical ? { height: label.percentage + '%' } : { width: label.percentage + '%' }]">
                <span class="c-progress-bar__label-title">{{ label.name }}</span>
                <span class="c-progress-bar__label-surface || js-label-surface">{{ Math.round(label.surface) }} m2</span>
            </div>
        </div>

        <div class="c-progress-bar__container || js-handle-container">
            <div class="c-progress-bar__indicator || js-handle" :data-index="index" :data-id="node.id" v-for="(node, index) in handleBars" :key="'handle-' + node.id"></div>

            <div class="c-progress-bar__steps || js-step-container">
                <div class="c-progress-bar__step" v-for="(step, index) in steps" :key="'step-' + index" v-bind:style="[ isVertical ? { top: step + 'px' } : { left: step + 'px' }]"></div>
            </div>
        </div>

        <span class="c-progress-bar__help-text">Verschuif de parameters om uw eigen indeling te bepalen.</span>
    </div>
</template>

<script>
    /* eslint-disable no-mixed-operators */
    import { mapActions, mapGetters } from 'vuex';
    import TweenLite from 'gsap/TweenLite';
    import Draggable from 'gsap/Draggable';
    import { pricesService } from '../services/prices.service';

    export default {
        name: 'ProgressBar',
        props: {
            roles: {
                type: Array,
                required: true,
            },
            surfaceArea: {
                type: Number,
                required: true,
            },
        },
        computed: {
            isVertical () {
                return window.innerWidth < 600;
            },
            relativePercentage () {
                // sum all the percentages
                return this.roles.reduce((total, role) => total + role.percentage, 0);
            },
            handleBars () {
                // remove 1 of the handles | -- | -- |
                return this.roles.slice(0, this.roles.length - 1);
            },
            ...mapGetters('summary', { summary: 'getSummaryByIndex' }),
        },
        watch: {
            surfaceArea () {
                console.log('surface area has changed');

                // update surface surfaceArea
                for (const label of this.labels)
                {
                    label.surface = (this.surfaceArea / 100) * label.percentage;
                }

                // update price
                this.updatePrice({
                    style: this.summary('style').map((style) => style.id),
                    roles: this.labels,
                });
            },
        },
        data () {
            return {
                labels: [],
                currentPercentage: 0,
                nextPercentage: 0,
                step: 5,
                steps: [],
            };
        },
        methods: {
            createLabels () {
                console.log('setLabels');

                this.labels = [];

                for (const role of this.roles)
                {
                    const { quantity } = this.$store.state.summary.role.find((label) => label.id === role.id);
                    console.log(quantity);
                    const percentage = quantity > 1 ? (quantity / this.surfaceArea) * 100 : (role.percentage / this.relativePercentage) * 100;
                    const surface = quantity > 1 ? quantity : (this.surfaceArea / this.relativePercentage) * role.percentage;

                    // const width = this.maxDrag / this.relativePercentage * role.percentage;
                    // const closestStep = this.steps.reduce((prev, curr) => Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev);
                    // console.log(closestStep, width);
                    // console.log({ quantity, percentage, surface });

                    this.labels.push({
                        id: role.id,
                        name: role.name,
                        // percentage,
                        percentage: percentage.toFixed(3),
                        // surface,
                        surface: Math.round(surface),
                    });
                }

                console.log(this.labels);

                // calculate total percentage
                const totalPercentage = this.labels.reduce((total, label) => total + label.percentage, 0);

                // the total percentage cannot be bigger then 100
                if (totalPercentage > 100)
                {
                    this.$store.commit('alert/error', { message: `Er ging iets mis met de percentages: ${totalPercentage}` });
                }

                // emit event to parent
                this.$emit('setLabels', { labels: this.labels });
            },
            setHandlePosition () {
                console.log('setPosition');

                for (const handle of this.handles)
                {
                    // get associated label
                    const { id } = handle.dataset;
                    const label = this.$el.querySelector(`.js-label[data-id="${id}"`);

                    // get x and y positions
                    const x = label.clientWidth + label.offsetLeft;
                    const y = label.clientHeight + label.offsetTop;

                    // find the closest step based on the x of the handle and the steps array
                    // const closestStep = this.steps.reduce((prev, curr) => Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev);

                    // console.log(closestStep, closestStep / this.maxDrag * 100);

                    // update start position
                    // label.dataset.start = this.currentPercentage;
                    // nextTargetEl.dataset.start = this.nextPercentage;

                    // update percentage
                    // label.dataset.percentage = closestStep / this.maxDrag * 100;
                    // currentTargetData.percentage = this.currentPercentage;
                    // nextTargetData.percentage = this.nextPercentage;

                    // set the handle
                    if (this.isVertical)
                    {
                        TweenLite.set(handle, { y });
                    }
                    else
                    {
                        TweenLite.set(handle, { x });
                    }
                }
            },
            createSteps () {
                console.log('createSteps');

                // get total steps
                const totalSteps = this.maxDrag / this.minDrag;

                // create steps
                for (let i = 1; i < totalSteps; i++)
                {
                    this.steps.push(this.minDrag * i);
                }
            },
            createDraggable () {
                console.log('createDraggable');

                const THIS = this;

                // create draggable
                Draggable.create('.js-handle', {
                    type: THIS.isVertical ? 'y' : 'x',
                    bounds: '.js-handle-container',
                    liveSnap: {
                        [THIS.isVertical ? 'y' : 'x']: this.steps,
                    },
                    onDrag () {
                        const current = THIS.isVertical ? this.y : this.x;
                        const currentProgress = (current / THIS.maxDrag * 100);

                        // get current element
                        const currentTargetData = THIS.labels.find((label) => parseFloat(this.target.dataset.id) === parseFloat(label.id));
                        const currentTargetEl = THIS.$el.querySelector(`.js-label[data-id="${currentTargetData.id}"`);
                        const currentTargetIndex = parseFloat(currentTargetEl.dataset.index);

                        // get next target
                        const nextTargetData = THIS.labels[currentTargetIndex + 1];
                        const nextTargetEl = THIS.$el.querySelector(`.js-label[data-index="${currentTargetIndex + 1}"`);

                        // get prev target
                        const prevTargetData = THIS.labels.filter((label, index) => parseFloat(this.target.dataset.id) !== parseFloat(label.id) && index <= currentTargetIndex);
                        const prevTargetPercentage = prevTargetData.reduce((accumulator, role) => accumulator + role.percentage, 0);

                        // get starting percentages
                        const currentStartPercentage = parseFloat(currentTargetEl.dataset.start);
                        const nextStartPercentage = parseFloat(nextTargetEl.dataset.start);

                        // get new percentages
                        const currentPercentage = (currentProgress - prevTargetPercentage);
                        const nextPercentage = (nextStartPercentage - (currentPercentage - currentStartPercentage));

                        // update label width
                        TweenLite.set(currentTargetEl, { [THIS.isVertical ? 'height' : 'width']: `${currentPercentage}%` });
                        TweenLite.set(nextTargetEl, { [THIS.isVertical ? 'height' : 'width']: `${nextPercentage}%` });

                        // update percentage
                        THIS.currentPercentage = currentPercentage;
                        THIS.nextPercentage = nextPercentage;

                        // update surface
                        currentTargetData.surface = (THIS.surfaceArea / 100) * currentPercentage;
                        nextTargetData.surface = (THIS.surfaceArea / 100) * nextPercentage;

                        // emit event to parent to update the summary
                        THIS.$emit('setLabels', { labels: THIS.labels });

                        // get boundaries
                        if (THIS.isVertical)
                        {
                            /*const currentHeight = currentTargetEl.clientHeight;
                            const nextHeight = nextTargetEl.clientHeight;
                            const top = currentTargetEl.offsetTop;
                            const height = currentHeight + nextHeight;

                            // update the boundaries so the handles do not cross each other
                            // eslint-disable-next-line
                            this.applyBounds﻿({ top, height });*/
                        }
                        else
                        {
                            const left = currentTargetEl.offsetLeft;
                            const width = currentTargetEl.clientWidth + nextTargetEl.clientWidth;

                            // update the boundaries so the handles do not cross each other
                            // eslint-disable-next-line
                            this.applyBounds﻿({ left, width });
                        }
                    },
                    onDragEnd () {
                        // get current element
                        const currentTargetData = THIS.labels.find((label) => parseFloat(this.target.dataset.id) === parseFloat(label.id));
                        const currentTargetEl = THIS.$el.querySelector(`.js-label[data-id="${currentTargetData.id}"`);
                        const currentTargetIndex = parseFloat(currentTargetEl.dataset.index);

                        // get next element
                        const nextTargetData = THIS.labels[currentTargetIndex + 1];
                        const nextTargetEl = THIS.$el.querySelector(`.js-label[data-index="${currentTargetIndex + 1}"`);

                        // update start position
                        currentTargetEl.dataset.start = THIS.currentPercentage;
                        nextTargetEl.dataset.start = THIS.nextPercentage;

                        // update percentage
                        currentTargetData.percentage = THIS.currentPercentage;
                        nextTargetData.percentage = THIS.nextPercentage;

                        // update price
                        THIS.updatePrice({
                            style: THIS.summary('style').map((style) => style.id),
                            roles: THIS.labels,
                        });
                    },
                });
            },
            updatePrice (params) {
                // clear error
                this.$store.commit('alert/clear');

                // get the price
                pricesService
                    .get(params)
                    .then((result) => {
                        const { style, roles } = result;

                        // update style
                        this.updateSummary({
                            payload: {
                                id: style.id,
                                price_m2: style.price_m2,
                                quantity: this.surfaceArea,
                            },
                            index: 'style',
                        });

                        // update role prices based on connections
                        for (const role of roles)
                        {
                            const { surface } = this.labels.find((label) => label.id === role.id);

                            // update role
                            this.updateSummary({
                                payload: {
                                    id: role.id,
                                    price_m2: role.price_m2,
                                    quantity: surface,
                                },
                                index: 'role',
                            });
                        }

                        // update interior
                        const interior = this.$store.state.summary.interior.find((node) => node.id);

                        if (typeof interior !== 'undefined')
                        {
                            // get kantoor quantity
                            const kantoor = this.$store.state.summary.role.find((node) => node.name === 'Kantoor');
                            const kantoorArea = typeof kantoor !== 'undefined' ? kantoor.quantity : 0;

                            // get showroom quantity
                            const showroom = this.$store.state.summary.role.find((node) => node.name === 'Showroom');
                            const showroomArea = typeof showroom !== 'undefined' ? showroom.quantity : 0;

                            this.updateSummary({
                                payload: {
                                    id: interior.id,
                                    price_m2: interior.price_m2,
                                    quantity: kantoorArea + showroomArea,
                                },
                                index: 'interior',
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$store.commit('alert/error', error);
                    });
            },
            ...mapActions('summary', ['addToSummary', 'resetSummary', 'updateSummary']),
        },
        created () {
            console.log('created');

            // reset errors
            this.$store.commit('alert/clear');

            // set starting percentages
            this.createLabels();
        },
        mounted () {
            console.log('mounted');

            // elements
            this.handles = this.$el.querySelectorAll('.js-handle');

            // check if there are any handles
            if (this.handles.length)
            {
                this.handleWidth = this.handles[0].clientWidth;
                this.handleHeight = this.handles[0].clientHeight;

                // get min and max drag
                this.maxDrag = this.isVertical ? this.$el.clientHeight - this.handleHeight : this.$el.clientWidth - this.handleWidth;
                this.minDrag = this.maxDrag / 100 * this.step;

                this.createSteps();
                this.setHandlePosition();
                this.createDraggable();
            }

            // update price
            this.updatePrice({
                style: this.summary('style').map((style) => style.id),
                roles: this.labels,
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    .c-progress-bar {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;

        &--is-vertical {
            flex-direction: row;
        }

        &__container {
            height: rem(60);
            position: relative;
            margin-bottom: rem(28); // note: height of help text

            .c-progress-bar--is-vertical & {
                height: 100%;
                margin-bottom: 0;
                // margin-left: rem(28);
                width: rem(60);
            }

            &:before {
                @include absolute(top 50% left 0 right 0);
                background-color: $gray-lightest;
                content: "";
                display: block;
                height: rem(5);
                transform: translateY(-50%);

                .c-progress-bar--is-vertical & {
                    top: 0;
                    bottom: 0;
                    right: auto;
                    left: 50%;
                    height: auto;
                    transform: translateX(-50%);
                    width: rem(4);
                }
            }
        }

        &__labels {
            display: flex;
            flex-wrap: wrap;

            .c-progress-bar--is-vertical & {
                @include fill;
                flex-direction: column;
            }
        }

        &__label {
            line-height: 1.2;
            text-align: center;
            white-space: nowrap;

            .c-progress-bar--is-vertical & {
                display: flex;
                flex-direction: column;
                font-size: rem(13);
                text-align: right;
                width: percentage(1 / 3);

                &:nth-child(odd) {
                    text-align: left;
                    margin-left: auto;
                }
            }

            &-title {
                text-transform: uppercase;
                display: block;

                .c-progress-bar--is-vertical & {
                    margin-top: auto;
                    /*white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: rem(100);*/
                }
            }

            &-surface {
                font-weight: $font-weight-black;

                .c-progress-bar--is-vertical & {
                    margin-bottom: auto;
                }
            }
        }

        &__indicator {
            @include absolute(top rem(5));
            background-color: $brand-success;
            box-shadow: 0 0 rem(10) $brand-success;
            border-radius: rem(5);
            height: rem(50);
            width: rem(5);

            .c-progress-bar--is-vertical & {
                left: rem(5);
                top: auto;
                height: rem(5);
                width: rem(50);
            }
        }

        &__steps {
            @include fill;
            pointer-events: none;
            z-index: -1;
        }

        &__step {
            @include absolute(top 50%);
            background-color: $gray-lighter;
            height: rem(30);
            transform: translateY(-50%);
            width: rem(2);

            .c-progress-bar--is-vertical & {
                top: auto;
                left: 50%;
                height: rem(2);
                transform: translateX(-50%);
                width: rem(30);
            }
        }

        &__help-text {
            display: none;

            @include media-breakpoint-up(sm) {
                @include absolute(bottom 0 left rem(5));
                color: $gray;
                display: block;
            }
        }
    }
</style>
