<template>
    <div class="o-container">
        <app-intro>
            <h1>Selecteer uw afmetingen</h1>
            <h3>Wat zijn de gewenste afmetingen van uw bedrijfspand?</h3>
        </app-intro>

        <section class="c-sizes">
            <div class="c-card c-length">
                <label class="c-sizes-title" for="length">Totale lengte</label>
                <select name="length" class="form-control" id="length" :value="currentLength" @change="onChangeSelect($event, startingLength)">
                    <option selected value="0">Kies een lengte</option>
                    <option :value="length" :key="length" v-for="length in lengths">{{ length }}</option>
                </select>
            </div>

            <div class="c-card c-width">
                <label class="c-sizes-title" for="width">Totale breedte</label>
                <select name="width" class="form-control" id="width" :value="currentWidth" @change="onChangeSelect($event, startingWidth)">
                    <option selected value="0">Kies een breedte</option>
                    <option :value="width" :key="width" v-for="width in widths">{{ width }}</option>
                </select>
            </div>

            <div class="c-card c-progress" v-if="currentLength > 0 && currentWidth > 0">
                <progress-bar v-on:setLabels="setLabels" v-bind:roles="roles" v-bind:surfaceArea="surfaceArea" />
            </div>

            <div class="c-card c-progress" v-if="currentLength === 0 || currentWidth === 0">
                Kies aub de lengte en/of breedte van uw pand
            </div>

            <div class="c-summary">
                <ul class="c-summary__list">
                    <li class="c-summary__item">
                        <span class="c-summary__item-title">Totale oppervlakte</span>
                        <span class="c-sizes-title">{{ surfaceArea }} m2</span>
                    </li>
                    <li class="c-summary__item">
                        <span class="c-summary__item-title">Pand hoogte</span>
                        <span>{{ height }}m</span>
                    </li>
                </ul>

                <ul class="c-summary__list">
                    <li class="c-summary__item" v-for="label in labels" :key="label.id">
                        <span class="c-summary__item-title">Oppervlakte {{ label.name.toLowerCase() }}</span>
                        <span>{{ Math.round(label.surface) }} m2</span>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AppIntro from '../components/app/AppIntro.vue';
    import ProgressBar from '../components/ProgressBar.vue';

    export default {
        components: { ProgressBar, AppIntro },
        data () {
            return {
                labels: [],
                startingLength: { id: 'length', name: 'Totale lengte', price_m2: 0 },
                startingWidth: { id: 'width', name: 'Totale breedte', price_m2: 0 },
            };
        },
        computed: {
            lengths () {
                const lengths = [];
                const { length } = this.summary('style').find((style) => style);
                const { min, max } = length;

                for (let i = min; i <= max; i += 5)
                {
                    lengths.push(i);
                }

                return lengths;
            },
            currentLength () {
                const { length } = this.summary('style').find((style) => style);
                const size = this.summary('size').find((item) => item.id === 'length');
                return size ? size.quantity : length.min;
            },
            widths () {
                const widths = [];
                const { width } = this.summary('style').find((style) => style);
                const { min, max } = width;

                for (let i = min; i <= max; i += 5)
                {
                    widths.push(i);
                }

                return widths;
            },
            currentWidth () {
                const { width } = this.summary('style').find((style) => style);
                const size = this.summary('size').find((item) => item.id === 'width');
                return size ? size.quantity : width.min;
            },
            surfaceArea () {
                return this.currentLength * this.currentWidth;
            },
            height () {
                const { height } = this.summary('style').find((style) => style);
                return height;
            },
            roles () {
                return this.summary('role');
            },
            ...mapGetters('summary', {
                summary: 'getSummaryByIndex',
                area: 'getSurfaceArea',
                isSelected: 'isSelected',
            }),
        },
        watch: {
            surfaceArea () {
                // update area in the store
                this.updateSurfaceArea(this.surfaceArea);
            },
        },
        methods: {
            setLabels ({ labels }) {
                this.labels = labels;
            },
            updateSummaryArea (index) {
                const summary = this.summary(index).find((node) => node.id);

                if (summary)
                {
                    this.updateSummary({
                        payload: {
                            id: summary.id,
                            price_m2: summary.price_m2,
                            quantity: this.surfaceArea,
                        },
                        index,
                    });
                }
            },
            onChangeSelect (event, data) {
                this.addToSummary({
                    payload: {
                        id: data.id,
                        name: data.name,
                        price_m2: data.price,
                        quantity: parseInt(event.target.value, 10),
                    },
                    index: 'size',
                    type: 'select',
                });

                // update area for interior
                this.updateSummaryArea('interior');

                // update area for installation
                this.updateSummaryArea('installation');
            },
            ...mapActions('summary', ['addToSummary', 'updateSummary', 'updateSurfaceArea']),
            ...mapActions('tabs', ['updateTab']),
        },
        created () {
            this.$store.commit('tabs/setCurrentTab', this.$route.name);

            // update area in the store
            this.updateSurfaceArea(this.surfaceArea);

            // add starting length to summary
            this.addToSummary({
                payload: {
                    ...this.startingLength,
                    quantity: this.currentLength,
                },
                index: 'size',
                type: 'select',
            });

            // add starting width to summary
            this.addToSummary({
                payload: {
                    ...this.startingWidth,
                    quantity: this.currentWidth,
                },
                index: 'size',
                type: 'select',
            });

            // update tab
            this.updateTab({
                index: 'size',
                type: 'select',
            });
        },
        beforeRouteEnter (to, from, next) {
            next((vm) => {
                const { tabs } = vm.$store.state;
                const isDisabled = tabs.tabs[tabs.currentTab].disabled;

                if (isDisabled)
                {
                    const index = 'size';
                    const nextTabs = vm.$store.getters['tabs/getNextTabsFromIndex'](index);
                    vm.$store.dispatch('tabs/disableTabs', nextTabs);
                    vm.$store.dispatch('summary/resetSummary', [index]);
                    vm.$store.dispatch('summary/updateSurfaceArea', 0);
                    next('/');
                }
                else
                {
                    next();
                }
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .c-sizes {
        display: grid;
        grid-gap: rem(15);
        grid-template-areas: "length"
                             "width"
                             "progress"
                             "summary";

        @include media-breakpoint-up(md) {
            grid-gap: 0;
            grid-template-columns: 1fr 1fr rem(235);
            grid-template-rows: rem(120) 1fr;
            grid-template-areas: "length width summary"
                                 "progress progress summary";
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 1fr rem(300);
        }

        &-title {
            color: $gray-dark;
            font-size: rem(24);
            font-weight: $font-weight-black;
            // filter: drop-shadow(0 0 rem(3) rgba(black, .15));
            line-height: 1;
            margin-bottom: 0;

            @include media-breakpoint-only(md) {
                font-size: rem(18);
            }

            @include media-breakpoint-up(xl) {
                font-size: rem(30);
            }
        }
    }

    .c-length,
    .c-width {
        align-items: center;
        display: flex;

        @include media-breakpoint-up(md) {
            border-bottom: none;
            padding: rem(30);
        }

        .c-sizes-title {
            width: 50%;

            @include media-breakpoint-up(xl) {
                width: 100%;
            }
        }
    }

    .c-length {
        grid-area: length;

        @include media-breakpoint-up(md) {
            border-right: none;
        }
    }

    .c-width {
        grid-area: width;

        @include media-breakpoint-up(md) {
            position: relative;

            &:before,
            &:after {
                @include square(0);
                border-style: solid;
                content: "";
                top: 50%;
                transform: translateY(-50%);
            }

            &:before {
                @include absolute(right rem(-10));
                border-width: rem(10) 0 rem(12) rem(10);
                border-color: transparent transparent transparent $white;
                z-index: 1;
            }

            &:after {
                @include absolute(right rem(-12));
                border-width: rem(12) 0 rem(14) rem(12);
                border-color: transparent transparent transparent $gray-light;
            }
        }
    }

    .c-progress {
        display: flex;
        grid-area: progress;
        min-height: 70vh;

        @include media-breakpoint-up(sm) {
            min-height: rem(300);
        }
    }

    .c-summary {
        grid-area: summary;
        padding: rem(40) rem(30);

        @include media-breakpoint-up(md) {
            padding: rem(30);
        }

        @include media-breakpoint-only(md) {
            font-size: rem(14);
        }

        @include media-breakpoint-up(lg) {
            padding: 0 0 0 rem(75);
        }

        &__list {
            @include zero;
            list-style: none;

            & + & {
                border-top: rem(1) solid $gray-light;
                margin-top: rem(15);
                padding-top: rem(15);

                @include media-breakpoint-up(lg) {
                    padding-top: rem(35);
                }
            }
        }

        &__item {
            display: block;
            position: relative;

            & + & {
                border-top: rem(1) solid $gray-light;
                margin-top: rem(15);
                padding-top: rem(15);

                @include media-breakpoint-up(lg) {
                    padding-top: rem(35);
                }
            }

            &-title {
                color: $brand-primary;
                display: block;
                font-weight: $font-weight-black;
                text-transform: uppercase;
            }

            &-icon {
                @include relative(top rem(2) left rem(5));
                transition: $transition-base;
                text-transform: none;

                svg {
                    transition: $transition-base;

                    text,
                    path {
                        transition: $transition-base;
                    }
                }

                @include hover-focus {
                    color: $white;

                    svg {
                        path {
                            fill: $brand-primary;
                        }

                        text {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
</style>
